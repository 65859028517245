import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import Alert from "react-bootstrap/Alert"
import { injectIntl } from "react-intl"
import ReactMarkdown from "react-markdown"

import Error from "./error"
import Loading from "./loading"

class ExchangeDeposit extends Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)

    this.state = {
      isLoaded: false,
      error: null,
      depositDetails: null,
    }
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 60 * 1000)
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  refresh() {
    FingerprintJS.load().then(fpAgent => {
      // new FingerprintJS().get(fingerprint => {
      fpAgent.get().then(fpResult => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL(
              "/exchange/deposit_details",
              process.env.GATSBY_API_URL
            )
            const params = {}
            params.fingerprint = fpResult.visitorId
            // params.fingerprint = fingerprint
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
              .then(response => {
                if (response.ok) {
                  response.json().then(json => {
                    this.setState({
                      isLoaded: true,
                      error: null,
                      depositDetails: json,
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({ isLoaded: true, error: text })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  isLoaded: true,
                  error: "Error: " + error.message + ".",
                })
              })
          })
      })
    })
  }

  render() {
    const { intl } = this.props
    const { isLoaded, error } = this.state
    if (!isLoaded) {
      return <Loading />
    } else if (error) {
      return <Error error={error} />
    } else {
      return (
        <Alert variant="info" className="text-justify">
          <ReactMarkdown
            children={intl.formatMessage(
              { id: "Exchange.Deposit.Details" },
              {
                accountKey: this.state.depositDetails.accountKey,
                maxAmountInEur: this.state.depositDetails.maxAmountInEur,
              }
            )}
          />
        </Alert>
      )
    }
  }
}

export default withAuth0(injectIntl(ExchangeDeposit))
