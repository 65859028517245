import React from "react"

import { withAuthenticationRequired } from "@auth0/auth0-react"
import Alert from "react-bootstrap/Alert"
import Card from "react-bootstrap/Card"
import { FormattedMessage } from "react-intl"
import { useIntl } from "react-intl"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExchangeNavigation from "../components/exchange_navigation"
import Layout1 from "../components/layout_1"
import ExchangeDeposit from "../components/exchange_deposit"

const ExchangeDepositPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Deposit" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <ExchangeNavigation location={location} />
      <Layout1
        center={
          <Card className="border-dark">
            <Card.Header className="text-white bg-dark">
              <FormattedMessage id={"Deposit"} />
            </Card.Header>
            <Card.Body>
              <ExchangeDeposit />
            </Card.Body>
          </Card>
        }
        right={
          <Alert variant="secondary">
            <ReactMarkdown
              children={intl.formatMessage({ id: "Exchange.Deposit" })}
            />
          </Alert>
        }
      />
    </Layout>
  )
}

export default withAuthenticationRequired(ExchangeDepositPage)
